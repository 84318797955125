export const mapValue = value => {
  const map = {
    portofolio: 'Ujian Portofolio',
    competency: 'Ujian Observasi',
    K: 'Direkomendasikan',
    BK: 'Belum Direkomendasikan',
    L: 'Kompeten',
    TL: 'Belum Kompeten',
  };

  return map[value] || value || '-';
};
