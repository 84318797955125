import { Button, Dropdown } from 'antd';
import {
  EyeOutlined,
  MoreOutlined,
  DownloadOutlined,
  EditOutlined,
  DeleteOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { useState } from 'react';
import { downloadFile } from '../../../helpers/file';

const DropdownAction = ({ item, handleDelete, handleEdit, handlePreview }) => {
  const [loading, setLoading] = useState(false);

  const handleDownload = () => {
    if (!item?.link_certificate) {
      return;
    }
    setLoading(true);
    downloadFile(item?.link_certificate, item?.title).finally(() => setLoading(false));
  };

  return (
    <Dropdown
      disabled={loading}
      menu={{
        items: [
          {
            key: '1',
            icon: <EyeOutlined />,
            label: 'Preview Certificate',
            onClick: () => handlePreview(item),
            disabled: !item?.link_certificate,
          },
          {
            key: '2',
            icon: <DownloadOutlined />,
            label: 'Download',
            onClick: handleDownload,
            disabled: !item?.link_certificate,
          },
          { key: '3', icon: <EditOutlined />, label: 'Edit', onClick: () => handleEdit(item) },
          { key: '4', type: 'divider' },
          { key: '5', icon: <DeleteOutlined />, label: 'Hapus', danger: true, onClick: () => handleDelete(item) },
        ],
      }}
      placement="bottomRight"
    >
      <Button size="small" icon={loading ? <LoadingOutlined /> : <MoreOutlined />} />
    </Dropdown>
  );
};

export default DropdownAction;
