import { Button, Dropdown, Empty, Input, Space, Spin } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { cloneElement, useEffect, useState } from 'react';
import docIcon from '../../assets/icons/doc.svg';
import searchIcon from '../../assets/icons/search.svg';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { debounce } from 'lodash';
import { useDispatch } from 'react-redux';
import { getCategories, getDocuments } from '../../pages/document/services/action';
import { BUCKET_URL } from '../../configs/config';
import DocumentViewer from '../../components/DocumentViewer';

const InputSearch = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [openSearch, setOpenSearch] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [documents, setDocuments] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loadingDocuments, setLoadingDocuments] = useState(false);
  const [loadingCategories, setLoadingCategories] = useState(false);
  const [docSelected, setDocSelected] = useState(null);
  const [openViewer, setOpenViewer] = useState(false);

  const handleSearch = debounce(e => {
    setSearchValue(e.target.value);
    if (!e.target.value) {
      setDocuments([]);
      setCategories([]);
      return;
    }
    setLoadingCategories(true);
    setLoadingDocuments(true);
    dispatch(getCategories({ has_documents: true, search: e.target.value, limit: 9 }, false))
      .then(res => setCategories((res || []).slice(0, 6)))
      .finally(() => setLoadingCategories(false));
    dispatch(getDocuments({ search: e.target.value, limit: 3 }, false))
      .then(res => setDocuments((res?.items || []).slice(0, 3)))
      .finally(() => setLoadingDocuments(false));
  }, 500);

  const handleSearchOther = () => {
    if (categories.length === 0 && documents.length === 0) {
      return;
    }
    setOpenSearch(false);
    navigate(`/document?search=${searchValue}`);
  };

  useEffect(() => {
    setSearchValue(searchParams.get('search'));
    if (searchParams.get('search')) {
      dispatch(getCategories({ has_documents: true, search: searchParams.get('search'), limit: 9 }, false))
        .then(res => setCategories((res || []).slice(0, 6)))
        .finally(() => setLoadingCategories(false));
      dispatch(getDocuments({ search: searchParams.get('search'), limit: 3 }, false))
        .then(res => setDocuments((res?.items || []).slice(0, 3)))
        .finally(() => setLoadingDocuments(false));
    }
  }, []);

  const handleClickCategory = categoryId => {
    dispatch({ type: 'set', categorySearchId: categoryId });
    navigate('/document');
  };

  const handleSelectDoc = doc => {
    setDocSelected(doc);
    setOpenViewer(true);
  };

  const handleCloseViewer = () => {
    setOpenViewer(false);
    setDocSelected(null);
  };

  return (
    <div style={{ height: '64px', overflow: 'hidden' }}>
      <Dropdown
        menu={{
          items: documents.map((item, idx) => ({
            key: idx,
            label: (
              <div className="flex items-center" onMouseDown={() => handleSelectDoc(item)}>
                <div>
                  <img src={docIcon} width={40} />
                </div>
                <div className="ml-3 w-full">
                  <div className="flex justify-between">
                    <div>
                      <div>{item.document_name}</div>
                      <div className="text-gray-400">
                        {item.category}
                        {item.sub_category && ' - ' + item.sub_category}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ),
          })),
        }}
        open={openSearch && !!searchValue}
        placement="bottom"
        trigger="click"
        dropdownRender={menu => (
          <div className="bg-white rounded-lg shadow-lg border">
            {!loadingCategories && categories.length > 0 && (
              <div style={{ fontSize: 14, color: '#0A0A0A', padding: '18px 18px 0 18px' }}>
                <div style={{ fontSize: 16, fontWeight: 500 }}>Berdasarkan Kategori Dokumen</div>
                <div className="pointer text-black mt-3">
                  <div className="flex flex-wrap">
                    {categories.map((item, idx) => (
                      <Space
                        key={idx}
                        className="w-1/3 mb-3 hover:text-primary"
                        onMouseDown={() => handleClickCategory(item.id)}
                      >
                        <SearchOutlined />
                        {item.category}
                      </Space>
                    ))}
                  </div>
                </div>
              </div>
            )}
            {!loadingDocuments && documents.length > 0 && (
              <div>
                <div style={{ fontSize: 16, padding: '8px 18px', fontWeight: 500 }}>Berdasarkan Dokumen</div>
                {cloneElement(menu, {
                  style: { boxShadow: 'none', padding: '0 6px' },
                })}
              </div>
            )}
            {!loadingDocuments && (categories.length > 0 || documents.length > 0) && (
              <div style={{ padding: '18px' }}>
                <Button block onMouseDown={handleSearchOther}>
                  Lihat Selengkapnya
                </Button>
              </div>
            )}
            {categories.length === 0 && documents.length === 0 && !loadingCategories && !loadingDocuments && (
              <div className="p-5">
                <Empty
                  image={searchIcon}
                  imageStyle={{ display: 'flex', justifyContent: 'center' }}
                  description={`Hasil Pencarian "${searchValue}" tidak ditemukan`}
                />
              </div>
            )}
            {(loadingCategories || loadingDocuments) && (
              <div className="p-5 py-10 centered">
                <Spin tip="Searching..." />
              </div>
            )}
          </div>
        )}
      >
        <Input
          placeholder="Cari Dokumen"
          defaultValue={searchParams.get('search')}
          onMouseUp={() => setOpenSearch(true)}
          onBlur={() => setOpenSearch(false)}
          onChange={handleSearch}
          prefix={<SearchOutlined style={{ color: '#5396C7' }} />}
          className="input-search"
          onPressEnter={handleSearchOther}
        />
      </Dropdown>
      <DocumentViewer
        open={openViewer}
        onClose={handleCloseViewer}
        fileName={docSelected?.document_name}
        subFileName={docSelected?.category + (docSelected?.sub_category ? ` - ${docSelected?.sub_category}` : '')}
        fileUrl={BUCKET_URL + docSelected?.file_path}
      />
    </div>
  );
};

export default InputSearch;
