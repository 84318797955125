import { useDispatch, useSelector } from 'react-redux';
import { deleteAlumni, getAlumniList } from '../services/action';
import { Modal, Table } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { alertSuccess } from '../../../helpers/alert';
import AlumniColumns from '../utils/columns';
import { useState } from 'react';
import { getTokenData } from '../../../helpers/token';
import { deleteFileS3 } from '../../../helpers/aws-s3';

const AlumniList = ({ handleEdit, handlePreview, selectedRows = [], setSelectedRows }) => {
  const dispatch = useDispatch();
  const alumniList = useSelector(state => state.alumni.alumniList);
  const loadingList = useSelector(state => state.alumni.loadingList);
  const pagination = useSelector(state => state.alumni.pagination);
  const params = useSelector(state => state.alumni.params);
  const [hoverSelected, setHoverSelected] = useState(null);
  const dirName = getTokenData().lsp_name + '/Sertifikat';

  const onPaginationChange = (page, limit) => {
    dispatch(getAlumniList({ ...params, page, limit }));
  };

  const handleDelete = item => {
    Modal.confirm({
      icon: <ExclamationCircleOutlined className="mt-1" />,
      title: 'Apakah anda yakin ingin menghapus pemegang sertifikat ini?',
      onOk: () =>
        deleteAlumni(item?.id).then(() => {
          if (item.link_certificate) {
            deleteFileS3(dirName, item.title, item.link_certificate);
          }
          dispatch(getAlumniList(params));
          alertSuccess('Data pemegang sertifikat berhasil dihapus');
        }),
      okText: 'Iya',
      cancelText: 'Batal',
    });
  };

  const handleSelect = (_keys, rows) => {
    setSelectedRows(rows);
  };

  return (
    <Table
      className="border rounded-lg overflow-hidden"
      loading={loadingList}
      columns={AlumniColumns({ selected: selectedRows, handleDelete, handleEdit, handlePreview })}
      rowKey="id"
      dataSource={alumniList}
      pagination={{
        current: pagination?.current || 1,
        onChange: onPaginationChange,
        total: pagination?.total || 0,
        showSizeChanger: true,
        pageSize: pagination?.limit || 10,
        pageSizeOptions: [5, 10, 25, 50],
        position: ['bottomLeft'],
        style: { padding: '1rem', margin: 0, background: '#fff' },
      }}
      scroll={{ x: 'max-content', y: 'max-content' }}
      rowSelection={{
        onChange: handleSelect,
        selectedRowKeys: selectedRows.map(item => item.id),
        getCheckboxProps: row => ({
          style: {
            display: row.id === hoverSelected?.id || selectedRows.some(item => item.id === row.id) ? '' : 'none',
          },
        }),
        columnWidth: 40,
      }}
      onRow={row => ({ onMouseEnter: () => setHoverSelected(row), onMouseLeave: () => setHoverSelected(null) })}
    />
  );
};

export default AlumniList;
