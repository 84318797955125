import { useDispatch, useSelector } from 'react-redux';
import { getNotifications, readNotification } from '../../stores/notification/action';
import { formatRelativeTime } from '../../helpers/string';
import { Empty } from 'antd';

const NotifChildren = ({ isContainUnread }) => {
  const dispatch = useDispatch();
  const notifications = useSelector(state => state.notification.list);

  const handleReadNotification = id => {
    dispatch(readNotification(id)).then(() => dispatch(getNotifications()));
  };

  return (
    <>
      <div className="overflow-auto max-h-96">
        {notifications.map((item, idx) => (
          <div
            key={idx}
            className={`p-5 border-b-2 ${item.is_read ? '' : 'cursor-pointer'}`}
            style={{ background: item.is_read ? '#FFF' : '#F0F6FA' }}
            onClick={!item.is_read ? () => handleReadNotification(item.id) : null}
          >
            <div className="text-right text-gray-400">{formatRelativeTime(item.created_at)}</div>
            <div className={!item.is_read ? 'font-semibold' : ''}>{item.description}</div>
          </div>
        ))}
        {notifications.length === 0 && (
          <div className="p-5">
            <Empty description="Tidak ada notifikasi" />
          </div>
        )}
      </div>
      {isContainUnread && (
        <div className="p-3 text-center">
          <span
            className="cursor-pointer text-primary font-semibold hover:font-bold"
            onClick={() => handleReadNotification()}
          >
            Tandai semua sudah dibaca
          </span>
        </div>
      )}
    </>
  );
};

export default NotifChildren;
