import { Layout } from 'antd';
import React from 'react';
import AppContent from './content/AppContent';
import AppHeader from './header/AppHeader';
import AppSidebar from './sidebar/AppSidebar';
import { useSelector } from 'react-redux';

const DashboardLayout = () => {
  const backdrop = useSelector(state => state.app.backdrop);

  return (
    <Layout>
      <AppHeader />
      <Layout>
        <AppSidebar />
        <AppContent />
        {backdrop && (
          <div className="inset-0 fixed" style={{ zIndex: 1000, top: 64, background: 'rgb(10 10 10 / 25%)' }}></div>
        )}
      </Layout>
    </Layout>
  );
};
export default DashboardLayout;
