import React, { useEffect } from 'react';
import { UserOutlined } from '@ant-design/icons';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { getAuditTrails } from '../stores/audit-trail/action';
import { Spin } from 'antd';

const DebugTest = props => {
  const dispatch = useDispatch();
  const logs = useSelector(state => state.auditTrail.list);
  useEffect(() => {
    dispatch(getAuditTrails());
  }, []);

  const handleNext = () => {
    dispatch(getAuditTrails({}, true));
  };

  return (
    <div className="p-5 border h-52 overflow-auto" id="scrollableDiv">
      LOGS
      <InfiniteScroll
        dataLength={logs.length}
        hasMore={logs.length < 50}
        next={handleNext}
        scrollableTarget="scrollableDiv"
        loader={<Spin />}
      >
        {logs.map((item, idx) => (
          <div key={idx}>{item.api_groups}</div>
        ))}
      </InfiniteScroll>
    </div>
  );
};

export default DebugTest;
