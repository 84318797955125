const initialState = {
  list: [],
  params: {},
  loading: false,
  count: 0,
};

export default function auditTrail(state = initialState, action) {
  switch (action.type) {
    case 'setAuditTrails':
      return {
        ...state,
        list: action.payload?.items || [],
        count: action.payload?.itemsTotal || 0,
        params: action.params,
      };
    case 'setMoreAuditTrails':
      return {
        ...state,
        list: [...state.list, ...action.payload?.items] || [],
        count: action.payload?.itemsTotal || 0,
        params: action.params,
      };
    case 'setLoadingGetAuditTrails':
      return { ...state, loading: action.payload };

    default:
      return state;
  }
}
