import { NOTIFICATION_URL } from '../../configs/config';
import { httpAuth } from '../../helpers/http';

export const getNotifications = (params, isConcat) => async dispatch => {
  try {
    dispatch({ type: 'setLoadingGetNotifications', payload: true });
    const res = await httpAuth().get(`${NOTIFICATION_URL}/notifications`, { params });
    dispatch({ type: 'setLoadingGetNotifications', payload: false });
    isConcat
      ? dispatch({ type: 'setMoreNotifications', payload: res.data, params })
      : dispatch({ type: 'setNotifications', payload: res.data, params });
    return res.data;
  } catch (err) {
    dispatch({ type: 'setLoadingGetNotifications', payload: false });
    throw err?.response;
  }
};

export const readNotification = id => async dispatch => {
  try {
    dispatch({ type: 'setLoadingReadNotification', payload: true });
    let url = `${NOTIFICATION_URL}/notifications/read/all`;
    if (id) {
      url = `${NOTIFICATION_URL}/notifications/${id}/read`;
    }
    const res = await httpAuth().put(url);
    dispatch({ type: 'setLoadingReadNotification', payload: false });
    return res.data;
  } catch (err) {
    dispatch({ type: 'setLoadingReadNotification', payload: false });
    throw err?.response;
  }
};
