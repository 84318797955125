import { AUDIT_TRAIL_URL } from '../../configs/config';
import { httpAuth } from '../../helpers/http';

export const getAuditTrails = (params, isConcat) => async dispatch => {
  try {
    dispatch({ type: 'setLoadingGetAuditTrails', payload: true });
    const res = await httpAuth().get(`${AUDIT_TRAIL_URL}/audit_trails`, { params });
    dispatch({ type: 'setLoadingGetAuditTrails', payload: false });
    isConcat
      ? dispatch({ type: 'setMoreAuditTrails', payload: res.data, params })
      : dispatch({ type: 'setAuditTrails', payload: res.data, params });
    return res.data;
  } catch (err) {
    dispatch({ type: 'setLoadingGetAuditTrails', payload: false });
    throw err?.response;
  }
};
