import { Col, DatePicker, Form, Input, Modal, Row, Select, notification } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import UploadFile from '../../../components/UploadFile';
import { getAlumniList, importAlumni } from '../services/action';
import { alertSuccess } from '../../../helpers/alert';

moment.updateLocale('en', { invalidDate: '' });

const ModalImportForm = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const schemaList = useSelector(state => state.schema.schemaList);
  const loadingSubmit = useSelector(state => state.schema.loadingSubmit);
  const tukList = useSelector(state => state.schedule.tukList);

  const handleOk = () => {
    form
      .validateFields()
      .then(values => {
        const body = new FormData();
        body.append('alumni_csv', values.alumni_csv);
        body.append('title', values.title);
        body.append('start_date', moment(values.start_date || '').format('YYYY-MM-DD'));
        body.append('certification_schemes_id', values.certification_schemes_id);
        body.append('tuk_id', values.tuk_id);

        dispatch(importAlumni(body)).then(() => {
          alertSuccess('Data pemegang sertifikat berhasil diimport');
          handleClose();
          dispatch(getAlumniList({ page: 1, limit: 10 }));
        });
      })
      .catch(info => {
        console.log('Validate Failed:', info);
      });
  };

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal
      width={500}
      title="Import Data Pemegang Sertifikat"
      maskClosable={false}
      centered
      open={isOpen}
      onCancel={handleClose}
      cancelText="Batal"
      okText="Simpan"
      confirmLoading={loadingSubmit}
      onOk={handleOk}
      bodyStyle={{ padding: 0 }}
      wrapClassName="py-5"
      destroyOnClose
    >
      <Form name="basic" layout="vertical" form={form} scrollToFirstError>
        <div className="p-5 border-b-8 border-gray-100 pb-2">
          <Row gutter={10}>
            <Col sm={12}>
              <Form.Item
                name="title"
                label="Nama Kegiatan Asesmen"
                rules={[{ required: true, message: 'Silahkan input kode skema!' }]}
              >
                <Input placeholder="Nama Kegiatan Asesmen" className="input-view" />
              </Form.Item>
            </Col>
            <Col sm={12}>
              <Form.Item name="start_date" label="Tanggal Asesmen">
                <DatePicker
                  style={{ width: '100%' }}
                  format="DD/MM/YYYY"
                  placeholder="dd/mm/yyyy"
                  className="input-view"
                />
              </Form.Item>
            </Col>
            <Col sm={12}>
              <Form.Item
                name="certification_schemes_id"
                label="Skema Sertifikasi"
                rules={[{ required: true, message: 'Silahkan pilih skema sertifikasi!' }]}
              >
                <Select
                  placeholder="Pilih skema sertifikasi"
                  options={schemaList.map(item => ({ value: item?.id, label: item?.schema_name }))}
                  className="input-view"
                />
              </Form.Item>
            </Col>
            <Col sm={12}>
              <Form.Item name="tuk_id" label="TUK Pelaksana">
                <Select
                  placeholder="Pilih TUK pelaksana"
                  options={tukList.map(item => ({ value: item?.id, label: item?.tuk_name }))}
                  className="input-view"
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div className="p-5 border-b flex justify-between">
          <div className="w-2/3">
            <div className="font-semibold" style={{ fontSize: 16 }}>
              Unggah Data Pemegang Sertifikat
            </div>
            <div className="text-sm text-gray-500 mt-1">
              Isi data pemegang sertifikat sesuai dengan kolom yang sudah disediakan pada template
            </div>
          </div>
          <div className="w-1/3 font-semibold text-end">
            <a href="/templates/import_certificate_template.csv" download>
              Unduh Template
            </a>
          </div>
        </div>
        <div className="p-5">
          <Form.Item name="alumni_csv">
            <UploadFile
              accept="text/csv"
              acceptText="format only csv"
              onError={err => form.setFields([{ name: 'alumni_csv', errors: [err] }])}
            />
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

export default ModalImportForm;
