import { Button, Input, Modal, Popover, Space, Spin, Typography } from 'antd';
import {
  PlusOutlined,
  UploadOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { useEffect, useState } from 'react';
import ModalForm from './components/ModalForm';
import { useDispatch, useSelector } from 'react-redux';
import { deleteBulkAlumni, getAlumniList } from './services/action';
import DocumentViewer from '../../components/DocumentViewer';
import { getSchemaList } from '../schema/services/action';
import { getTuk } from '../schedule/services/action';
import ModalImportForm from './components/ModalImportForm';
import AlumniList from './components/AlumniList';
import { alertSuccess } from '../../helpers/alert';
import { debounce } from 'lodash';
import { FilterIcon } from '../../components/Icons';
import FilterContent from './components/FilterContent';
import { deleteFileS3 } from '../../helpers/aws-s3';
import { getTokenData } from '../../helpers/token';

const Alumni = () => {
  const dispatch = useDispatch();
  const [modalForm, setModalForm] = useState(false);
  const [modalImportForm, setModalImportForm] = useState(false);
  const [openViewer, setOpenViewer] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [formType, setFormType] = useState('Buat');
  const [selected, setSelected] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const alumniList = useSelector(state => state.alumni.alumniList);
  const loadingList = useSelector(state => state.alumni.loadingList);
  const params = useSelector(state => state.alumni.params);
  const dirName = getTokenData().lsp_name + '/Sertifikat';

  useEffect(() => {
    dispatch(getAlumniList({ page: 1, limit: 10 }));
    dispatch(getSchemaList());
    dispatch(getTuk());
  }, []);

  const handleAdd = () => {
    setSelected(null);
    setFormType('Buat');
    setModalForm(true);
  };

  const handleEdit = item => {
    setSelected(item);
    setFormType('Edit');
    setModalForm(true);
  };

  const handlePreview = item => {
    if (item?.link_certificate) {
      setSelected(item);
      setOpenViewer(true);
    }
  };

  const handleCloseViewer = () => {
    setOpenViewer(false);
  };

  const handleBulkDelete = () => {
    Modal.confirm({
      icon: <ExclamationCircleOutlined className="mt-1" />,
      title: 'Apakah anda yakin ingin menghapus pemegang sertifikat tersebut?',
      onOk: () =>
        deleteBulkAlumni({ alumnis: selectedRows.map(item => ({ alumnis_id: item.id })) }).then(() => {
          selectedRows.forEach(item => {
            if (item.link_certificate) {
              deleteFileS3(dirName, item.title, item.link_certificate);
            }
          });
          dispatch(getAlumniList(params));
          alertSuccess('Data pemegang sertifikat berhasil dihapus');
          setSelectedRows([]);
        }),
      okText: 'Iya',
      cancelText: 'Batal',
    });
  };

  const handleSearch = debounce(e => {
    dispatch(getAlumniList({ ...params, page: 1, search: e.target.value }));
  }, 500);

  return (
    <div className="p-5 h-full flex flex-col">
      <div className="center-y justify-between mb-4">
        <Typography.Title level={4} style={{ margin: 0 }}>
          Pemegang Sertifikat
        </Typography.Title>
        {alumniList.length > 0 && (
          <Space>
            <Input
              placeholder="Cari disini"
              prefix={<SearchOutlined style={{ color: '#5396C7' }} />}
              suffix={
                <Popover
                  placement="bottomRight"
                  overlayClassName="popover-filter"
                  content={FilterContent}
                  trigger="click"
                  open={openFilter}
                  onOpenChange={val => setOpenFilter(val)}
                >
                  <FilterIcon style={{ color: '#5396C7' }} />
                </Popover>
              }
              onChange={handleSearch}
            />
            {selectedRows.length > 0 && (
              <Button type="primary" danger icon={<DeleteOutlined />} onClick={handleBulkDelete}>
                Hapus Data
              </Button>
            )}
            <Button
              type="primary"
              className="semi-outlined"
              icon={<UploadOutlined />}
              onClick={() => setModalImportForm(true)}
            >
              Import Data
            </Button>
            <Button type="primary" icon={<PlusOutlined />} onClick={handleAdd}>
              Tambah Data
            </Button>
          </Space>
        )}
      </div>
      {alumniList.length > 0 && <AlumniList {...{ handleEdit, handlePreview, selectedRows, setSelectedRows }} />}
      {alumniList.length === 0 &&
        (loadingList ? (
          <div className="centered h-full">
            <Spin size="large" tip="Memuat..." />
          </div>
        ) : (
          <div className="centered h-full">
            <div className="text-center border-2 border-gray-300 border-dashed p-5 rounded-xl">
              <Typography.Text>Belum ada pemegang sertifikat yang bisa ditampilkan</Typography.Text>
              <div className="mt-5">
                <Space>
                  <Button
                    type="primary"
                    className="semi-outlined"
                    icon={<UploadOutlined />}
                    onClick={() => setModalImportForm(true)}
                  >
                    Import Data
                  </Button>
                  <Button type="primary" icon={<PlusOutlined />} onClick={handleAdd}>
                    Tambah Data
                  </Button>
                </Space>
              </div>
            </div>
          </div>
        ))}
      <ModalForm
        isOpen={modalForm}
        onClose={() => setModalForm(false)}
        formType={formType}
        data={selected}
        handlePreview={handlePreview}
      />
      <ModalImportForm isOpen={modalImportForm} onClose={() => setModalImportForm(false)} />
      <DocumentViewer
        open={openViewer}
        onClose={handleCloseViewer}
        fileName={selected?.title}
        fileUrl={selected?.link_certificate}
      />
    </div>
  );
};

export default Alumni;
