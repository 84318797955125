import { Button, Col, DatePicker, Form, Input, Modal, Row, Select, Tooltip } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UploadS3 from '../../../components/UploadS3';
import { getTokenData } from '../../../helpers/token';
import { numberOnly } from '../../../helpers/normalize';
import { addAlumni, getAlumniList, updateAlumni } from '../services/action';
import { InfoCircleFilled } from '@ant-design/icons';
import moment from 'moment';
import { alertSuccess } from '../../../helpers/alert';
import { PEDOMAN_BNSP } from '../../../configs/config';
import { formatPhone62 } from '../../../helpers/validator';
import { deleteFileS3 } from '../../../helpers/aws-s3';
import { debounce } from 'lodash';
import { getUsers } from '../../../stores/user/action';

moment.updateLocale('en', { invalidDate: '' });

const ModalForm = ({ isOpen, onClose, data, formType, handlePreview }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const dirName = getTokenData().lsp_name + '/Sertifikat';
  const docName = Form.useWatch('title', form);
  const schemaList = useSelector(state => state.schema.schemaList);
  const loadingSubmit = useSelector(state => state.schema.loadingSubmit);
  const tukList = useSelector(state => state.schedule.tukList);
  const users = useSelector(state => state.user.list);
  const params = useSelector(state => state.user.params);
  const loadingGetUser = useSelector(state => state.user.loading);

  useEffect(() => {
    if (isOpen) {
      const intialValue = {
        ...data,
        start_date: data?.start_date ? moment(data?.start_date) : null,
        expired_date: data?.expired_date ? moment(data?.expired_date) : null,
        certification_schemes_id: data?.certification_schemes_id ? parseInt(data?.certification_schemes_id) : null,
        tuk_id: data?.tuk_id ? parseInt(data?.tuk_id) : null,
      };
      form.setFieldsValue(data ? intialValue : null);
    }
    dispatch(getUsers({ role_code: 'ACS' }));
  }, [isOpen]);

  const handleOk = () => {
    form
      .validateFields()
      .then(values => {
        const body = {
          ...values,
          expired_date: moment(values.expired_date || '').format('YYYY-MM-DD'),
          start_date: moment(values.start_date || '').format('YYYY-MM-DD'),
          link_certificate: values.file_path?.location?.replace(/ /g, '+'),
        };
        if (data) {
          dispatch(updateAlumni(data.id, body)).then(() => {
            if (body.link_certificate !== data?.link_certificate && data?.link_certificate) {
              deleteFileS3(dirName, data?.title, data?.link_certificate);
            }
            alertSuccess('Data pemegang sertifikat berhasil diupdate');
            handleClose();
            dispatch(getAlumniList({ page: 1, limit: 10 }));
          });
        } else {
          dispatch(addAlumni(body)).then(() => {
            alertSuccess('Data pemegang sertifikat berhasil ditambahkan');
            handleClose();
            dispatch(getAlumniList({ page: 1, limit: 10 }));
          });
        }
      })
      .catch(info => {
        console.log('Validate Failed:', info);
      });
  };

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  const handleSearchUser = debounce(value => {
    dispatch(getUsers({ ...params, search: value }));
  }, 500);

  return (
    <Modal
      width={500}
      title="Isi Data Pemegang Sertifikat"
      maskClosable={false}
      centered
      open={isOpen}
      onCancel={handleClose}
      footer={[
        <Button key="back" onClick={handleClose}>
          {formType !== 'Detail' ? 'Batal' : 'Tutup'}
        </Button>,
        formType !== 'Detail' && (
          <Button type="primary" key="submit" onClick={handleOk} loading={loadingSubmit}>
            Simpan
          </Button>
        ),
      ]}
      bodyStyle={{ padding: 0 }}
      wrapClassName="py-5"
      destroyOnClose
    >
      <Form name="basic" layout="vertical" form={form} scrollToFirstError>
        <div className="p-5 border-b-8 border-gray-100 pb-2">
          <Row gutter={10}>
            <Col sm={12}>
              <Form.Item
                name="title"
                label="Nama Kegiatan Asesmen"
                rules={[{ required: true, message: 'Silahkan input kode skema!' }]}
              >
                <Input placeholder="Nama Kegiatan Asesmen" disabled={formType !== 'Buat'} className="input-view" />
              </Form.Item>
            </Col>
            <Col sm={12}>
              <Form.Item name="start_date" label="Tanggal Asesmen">
                <DatePicker
                  style={{ width: '100%' }}
                  format="DD/MM/YYYY"
                  placeholder="dd/mm/yyyy"
                  disabled={formType !== 'Buat'}
                  className="input-view"
                />
              </Form.Item>
            </Col>
            <Col sm={12}>
              <Form.Item
                name="certification_schemes_id"
                label="Skema Sertifikasi"
                rules={[{ required: true, message: 'Silahkan pilih skema sertifikasi!' }]}
              >
                <Select
                  placeholder="Pilih skema sertifikasi"
                  options={schemaList.map(item => ({ value: item?.id, label: item?.schema_name }))}
                  disabled={formType !== 'Buat'}
                  className="input-view"
                />
              </Form.Item>
            </Col>
            <Col sm={12}>
              <Form.Item name="tuk_id" label="TUK Pelaksana">
                <Select
                  placeholder="Pilih TUK pelaksana"
                  options={tukList.map(item => ({ value: item?.id, label: item?.tuk_name }))}
                  disabled={formType !== 'Buat'}
                  className="input-view"
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div className="p-5">
          <Form.Item
            normalize={numberOnly}
            name="nik"
            label="NIK"
            rules={[
              { required: true, message: 'Silahkan input NIK!' },
              { len: 16, message: 'NIK harus 16 karakter' },
            ]}
          >
            <Input placeholder="NIK" maxLength={16} />
          </Form.Item>
          <Form.Item
            name="full_name"
            label="Nama Lengkap Asesi"
            rules={[{ required: true, message: 'Silahkan input Nama Lengkap Asesi!' }]}
          >
            <Input placeholder="Nama Lengkap Asesi" />
          </Form.Item>
          <Form.Item name="email" label="Email" rules={[{ type: 'email', message: 'Email harus valid' }]}>
            <Input placeholder="Email" />
          </Form.Item>
          <Form.Item
            name="phone_number"
            label="No Telepon"
            normalize={numberOnly}
            rules={[{ validator: formatPhone62 }]}
          >
            <Input placeholder="Input nomor telepon (628XXXXXXXXXX)" maxLength={15} />
          </Form.Item>
          <Form.Item name="institution" label="Institusi">
            <Input placeholder="Institusi" />
          </Form.Item>
          <Form.Item name="asesor_name" label="Asesor Penguji">
            <Select
              style={{ width: '100%' }}
              placeholder="Pilih Asesor Penguji"
              showSearch
              loading={loadingGetUser}
              onChange={(_, val) => form.setFieldValue('asesor_registration_number', val?.registration_number)}
              onSearch={handleSearchUser}
              options={users.map(item => ({
                label: `${item.first_name} ${item.last_name}`,
                value: `${item.first_name} ${item.last_name}`,
                key: item.user_id,
                registration_number: item.registration_number,
              }))}
            />
          </Form.Item>
          <Form.Item name="asesor_registration_number" label="No Registrasi Asesor">
            <Input placeholder="No Registrasi Asesor" />
          </Form.Item>
          <Form.Item name="test_method" label="Metode Uji">
            <Select
              placeholder="Pilih metode uji"
              options={[
                { value: 'portofolio', label: 'Ujian Portofolio' },
                { value: 'competency', label: 'Ujian Observasi' },
              ]}
            />
          </Form.Item>
          <Form.Item name="status_recomendation" label="Status Rekomendasi">
            <Select
              placeholder="Pilih status rekomendasi"
              options={[
                { value: 'K', label: 'Direkomendasikan' },
                { value: 'BK', label: 'Belum Direkomendasikan' },
              ]}
            />
          </Form.Item>
          <Form.Item name="status_graduation" label="Status Keputusan Pleno">
            <Select
              placeholder="Pilih status keputusan pleno"
              options={[
                { value: 'L', label: 'Kompeten' },
                { value: 'TL', label: 'Belum Kompeten' },
              ]}
            />
          </Form.Item>
          <Form.Item name="blanko_number" label="No Blanko">
            <Input placeholder="No Blanko" />
          </Form.Item>
          <Form.Item name="certificate_number" label="No Sertifikat">
            <Input
              placeholder="No Sertifikat"
              suffix={
                <Tooltip
                  title={
                    <>
                      Format kodifikasi nomor sertifikat (00000 0000 0 0000000 2023) sesuai pedoman BNSP.{' '}
                      <a
                        href={PEDOMAN_BNSP}
                        target="_blank"
                        style={{ color: '#fff', fontWeight: '700' }}
                        rel="noopener"
                      >
                        Lihat disini
                      </a>
                    </>
                  }
                  color="#5396C7"
                >
                  <InfoCircleFilled style={{ color: '#5396C7' }} />
                </Tooltip>
              }
            />
          </Form.Item>
          <Form.Item name="registration_number" label="No Registrasi">
            <Input
              placeholder="No Registrasi"
              suffix={
                <Tooltip
                  title={
                    <>
                      Format kodifikasi nomor registrasi (000 001 00000 2023) sesuai pedoman BNSP.{' '}
                      <a
                        href={PEDOMAN_BNSP}
                        target="_blank"
                        style={{ color: '#fff', fontWeight: '700' }}
                        rel="noopener"
                      >
                        Lihat disini
                      </a>
                    </>
                  }
                  color="#5396C7"
                >
                  <InfoCircleFilled style={{ color: '#5396C7' }} />
                </Tooltip>
              }
            />
          </Form.Item>
          <Form.Item name="expired_date" label="Masa Berlaku">
            <DatePicker style={{ width: '100%' }} format="DD/MM/YYYY" placeholder="dd/mm/yyyy" />
          </Form.Item>
          <Form.Item name="file_path" label="Sertifikat Kompetensi">
            <UploadS3
              disabled={!docName}
              dirName={dirName}
              fileName={docName}
              onPreview={formType !== 'Buat' ? () => handlePreview(data) : undefined}
              initValue={data?.link_certificate ? { name: data?.title, file_url: data?.link_certificate } : null}
              accept="application/pdf, image/jpg, image/png, image/jpeg"
              acceptText="pdf, jpg, jpeg, dan png"
              onError={err => form.setFields([{ name: 'file_path', errors: [err] }])}
            />
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

export default ModalForm;
