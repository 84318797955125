import { Avatar, Button, Divider, Input, Popover, Space } from 'antd';
import { UserOutlined, LogoutOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { getTokenData, removeToken } from '../../helpers/token';
import { useNavigate } from 'react-router-dom';
import ModalProfile from './ModalProfile';
import ModalPassword from './ModalPassword';
import { useDispatch } from 'react-redux';

const AvatarMenu = () => {
  const [open, setOpen] = useState(false);
  const [modalProfile, setModalProfile] = useState(false);
  const [modalPassword, setModalPassword] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = getTokenData();

  const handleLogout = () => {
    removeToken();
    navigate(0);
  };

  const hanleOpenProfile = () => {
    setModalProfile(true);
    setOpen(false);
    dispatch({ type: 'set', backdrop: false });
  };

  const hanleOpenPassword = () => {
    setModalPassword(true);
    setOpen(false);
    dispatch({ type: 'set', backdrop: false });
  };

  const content = () => {
    return (
      <div className="p-2">
        <div className="mb-4 flex flex-col justify-center items-center">
          <Avatar size={80} className="border" src="" icon={<UserOutlined />} />
          <div className="flex mt-2">
            <div className="text-primary font-semibold pointer" onClick={hanleOpenProfile}>
              Edit Profile
            </div>
            <div className="mx-2 text-gray-400 font-semibold">|</div>
            <div className="text-primary font-semibold pointer" onClick={hanleOpenPassword}>
              Ubah Password
            </div>
          </div>
        </div>
        <div className="mb-3">
          <div className="mb-1">Nama Depan</div>
          <Input disabled className="input-view" value={user.first_name} />
        </div>
        <div className="mb-3">
          <div className="mb-1">Nama Belakang</div>
          <Input disabled className="input-view" value={user.last_name} />
        </div>
        <div>
          <div className="mb-1">Email</div>
          <Input disabled className="input-view" value={user.email} />
        </div>
        <Divider style={{ margin: '16px 0' }} />
        <Space direction="vertical" className="w-full">
          <Button type="primary" block href="https://app.nasonline.id" target="_blank">
            Go To NAS Sertifikasi
          </Button>
          <Button type="primary" danger block icon={<LogoutOutlined />} onClick={handleLogout}>
            Keluar
          </Button>
        </Space>
      </div>
    );
  };

  const handleOpen = value => {
    setOpen(value);
    dispatch({ type: 'set', backdrop: value });
  };

  return (
    <>
      <Popover
        placement="bottomRight"
        overlayClassName="popover-profile"
        content={content}
        trigger="click"
        open={open}
        onOpenChange={handleOpen}
      >
        <Avatar className="pointer" icon={<UserOutlined />} />
      </Popover>
      <ModalProfile open={modalProfile} onClose={() => setModalProfile(false)} />
      <ModalPassword open={modalPassword} onClose={() => setModalPassword(false)} />
    </>
  );
};

export default AvatarMenu;
