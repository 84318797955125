import { CalendarIcon, CertificateIcon, FolderIcon, HomeIcon, MedaliIcon, PeopleIcon } from '../components/Icons';

const menus = [
  {
    key: '/',
    icon: <HomeIcon />,
    label: 'Home',
  },
  {
    key: '/organization',
    icon: <PeopleIcon />,
    label: 'Organization Management',
  },
  {
    key: '/document',
    icon: <FolderIcon />,
    label: 'Document Management',
  },
  {
    key: '/schema',
    icon: <MedaliIcon />,
    label: 'Skema Sertifikasi',
  },
  {
    key: '/schedule',
    icon: <CalendarIcon />,
    label: 'Jadwal Kegiatan',
  },
  {
    key: '/alumni',
    icon: <CertificateIcon />,
    label: 'Pemegang Sertifikat',
  },
];

export default menus;
