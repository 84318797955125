const initialState = {
  list: [],
  params: {},
  loading: false,
  loadingRead: false,
};

export default function notifications(state = initialState, action) {
  switch (action.type) {
    case 'setNotifications':
      return {
        ...state,
        list: action.payload?.items || [],
        params: action.params,
      };
    case 'setMoreNotifications':
      return {
        ...state,
        list: [...state.list, ...action.payload?.items] || [],
        params: action.params,
      };
    case 'setLoadingGetNotifications':
      return { ...state, loading: action.payload };
    case 'setLoadingReadNotification':
      return { ...state, loadingRead: action.payload };

    default:
      return state;
  }
}
