import { Button, DatePicker, Form, Input, Modal } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import SelectWithCreate from '../../../components/SelectWithCreate';

const ModalCompetenceUnit = ({ isOpen, onClose, data, onCreated, onEditted, formType, listSelected = [] }) => {
  const [form] = Form.useForm();
  const competences = useSelector(state => state.schema.competences);
  const [unitCodes, setUnitCodes] = useState(
    competences.map(item => ({ value: item.id, label: item.unit_code, ...item }))
  );

  useEffect(() => {
    if (isOpen) {
      form.setFieldsValue({ ...data, unit_year: data?.unit_year ? moment(data?.unit_year) : null });
      const selectedIds = listSelected.map(item => item.unit_competences_id);
      const filteredUnitCodes = competences.filter(item => {
        return !selectedIds.includes(item.id) || item.id === data?.unit_competences_id;
      });
      setUnitCodes(filteredUnitCodes.map(item => ({ value: item.id, label: item.unit_code, ...item })));
    }
  }, [isOpen]);

  const handleOk = () => {
    form
      .validateFields()
      .then(values => {
        console.log(values);
        handleClose();
        const body = { ...values, unit_year: values.unit_year ? values.unit_year?.format('YYYY') : null };
        data ? onEditted(body) : onCreated(body);
      })
      .catch(info => {
        console.log('Validate Failed:', info);
      });
  };

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  const handleCreateUnitCode = value => {
    setUnitCodes([...unitCodes, { value, label: value }]);
    form.setFieldValue('unit_competences_id', value);
    form.setFieldValue('unit_code', value);
    form.setFieldValue('unit_name', '');
  };

  const onChangeUnitCode = (value, row) => {
    form.setFieldValue('unit_code', row.unit_code || value);
    form.setFieldValue('unit_name', row.unit_name);
  };

  return (
    <Modal
      width={400}
      title={`${data ? 'Edit' : 'Tambah'} Data Unit Kompetensi`}
      maskClosable={false}
      centered
      open={isOpen}
      footer={[
        <Button key="back" onClick={handleClose}>
          {formType !== 'Detail' ? 'Batal' : 'Tutup'}
        </Button>,
        formType !== 'Detail' && (
          <Button type="primary" key="submit" onClick={handleOk}>
            Simpan
          </Button>
        ),
      ]}
      onCancel={handleClose}
      destroyOnClose
    >
      <Form name="basic" layout="vertical" form={form}>
        <Form.Item
          name="unit_competences_id"
          label="Kode Unit Kompetensi"
          rules={[{ required: true, message: 'Silahkan pilih kode unit!' }]}
        >
          <SelectWithCreate
            placeholder="Pilih/Tambahkan Kode Unit"
            handleCreate={handleCreateUnitCode}
            onChange={onChangeUnitCode}
            options={unitCodes}
            disabled={formType === 'Detail'}
            className="input-view"
          />
        </Form.Item>
        <Form.Item name="unit_code" hidden>
          <Input />
        </Form.Item>
        <Form.Item
          name="unit_name"
          label="Nama Unit Kompetensi"
          rules={[{ required: true, message: 'Silahkan isi nama unit!' }]}
        >
          <Input placeholder="Nama Unit Kompetensi" disabled={formType === 'Detail'} className="input-view" />
        </Form.Item>
        <Form.Item name="unit_year" label="Tahun">
          <DatePicker
            className="w-full input-view"
            picker="year"
            placeholder={formType === 'Detail' ? '-' : 'Pilih Tahun'}
            disabled={formType === 'Detail'}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalCompetenceUnit;
