import { Button, DatePicker, Input, Select, Space } from 'antd';
import { debounce } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { getUsers } from '../../../stores/user/action';
import { useState } from 'react';
import { getAlumniList } from '../services/action';
import moment from 'moment';

const FilterContent = () => {
  const dispatch = useDispatch();
  const schemaList = useSelector(state => state.schema.schemaList);
  const users = useSelector(state => state.user.list);
  const paramsUser = useSelector(state => state.user.params);
  const loadingGetUser = useSelector(state => state.user.loading);
  const [filterData, setFilterData] = useState(null);
  const params = useSelector(state => state.alumni.params);

  const handleSearchUser = debounce(value => {
    dispatch(getUsers({ ...paramsUser, search: value }));
  }, 500);

  const handleFilter = (filterBy, type) => {
    let filterValue = filterData?.[filterBy];
    if (type === 'date') {
      filterValue = moment(filterValue).format('YYYY-MM-DD');
    }
    dispatch(getAlumniList({ ...params, page: 1, filter_by: filterBy, filter_value: filterValue }));
  };

  const handleReset = () => {
    setFilterData(null);
    dispatch(getAlumniList({ ...params, page: 1, filter_by: '', filter_value: '' }));
  };

  return (
    <div>
      <Space direction="vertical" size={12} className="w-full">
        <div className="flex items-end">
          <div className="w-full">
            <div className="mb-1">Filter by Skema Sertifikasi</div>
            <Select
              placeholder="Filter Skema Sertifikasi"
              options={schemaList.map(item => ({ value: item?.id, label: item?.schema_name }))}
              style={{ width: 240 }}
              value={filterData?.certification_schemes_id}
              onChange={val => setFilterData({ ...filterData, certification_schemes_id: val })}
              allowClear
            />
          </div>
          <Button className="ml-2" type="primary" ghost onClick={() => handleFilter('certification_schemes_id')}>
            Search
          </Button>
        </div>
        <div className="flex items-end">
          <div className="w-full">
            <div className="mb-1">Filter by Asesor Penguji</div>
            <Select
              style={{ width: 240 }}
              placeholder="Filter Asesor Penguji"
              showSearch
              allowClear
              value={filterData?.asesor_name}
              onChange={val => setFilterData({ ...filterData, asesor_name: val })}
              loading={loadingGetUser}
              onSearch={handleSearchUser}
              options={users.map(item => ({
                label: `${item.first_name} ${item.last_name}`,
                value: `${item.first_name} ${item.last_name}`,
                key: item.user_id,
              }))}
            />
          </div>
          <Button className="ml-2" type="primary" ghost onClick={() => handleFilter('asesor_name')}>
            Search
          </Button>
        </div>
        <div className="flex items-end">
          <div className="w-full">
            <div className="mb-1">Filter by Status Keputusan</div>
            <Select
              placeholder="Filter Status Keputusan"
              options={[
                { value: 'L', label: 'Kompeten' },
                { value: 'TL', label: 'Belum Kompeten' },
              ]}
              style={{ width: 240 }}
              value={filterData?.status_graduation}
              onChange={val => setFilterData({ ...filterData, status_graduation: val })}
              allowClear
            />
          </div>
          <Button className="ml-2" type="primary" ghost onClick={() => handleFilter('status_graduation')}>
            Search
          </Button>
        </div>
        <div className="flex items-end">
          <div className="w-full">
            <div className="mb-1">Filter by Masa Berlaku</div>
            <DatePicker
              style={{ width: 240 }}
              format="DD/MM/YYYY"
              placeholder="dd/mm/yyyy"
              value={filterData?.expired_date}
              onChange={val => setFilterData({ ...filterData, expired_date: val })}
            />
          </div>
          <Button className="ml-2" type="primary" ghost onClick={() => handleFilter('expired_date', 'date')}>
            Search
          </Button>
        </div>
        <Button type="primary" onClick={handleReset} disabled={!filterData}>
          Reset
        </Button>
      </Space>
    </div>
  );
};

export default FilterContent;
