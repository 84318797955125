import { Layout, Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import InputSearch from './InputSearch';
import AvatarMenu from './AvatarMenu';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getProfile } from '../../stores/app/action';
import Notification from './Notification';
import { getNotifications } from '../../stores/notification/action';
import { getAuditTrails } from '../../stores/audit-trail/action';

const { Header } = Layout;

const AppHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getProfile());
    dispatch(getNotifications());
    dispatch(getAuditTrails({ page: 1, limit: 10 }));
  }, []);

  return (
    <Header
      className="app-header"
      style={{
        padding: 0,
        position: 'sticky',
        top: 0,
        zIndex: 1000,
        width: '100%',
      }}
    >
      <div className="flex justify-between items-center">
        <Space align="center">
          <div className="logo">
            <img src="/logos/nas-text-logo.svg" alt="" className="pointer" onClick={() => navigate('/')} />
          </div>
          <div className="logo-title">Manajemen Mutu</div>
        </Space>
        <div className="w-2/5">
          <InputSearch />
        </div>
        <div className="flex items-center mr-4">
          <div className="mr-5">
            <Notification />
          </div>
          <AvatarMenu />
        </div>
      </div>
    </Header>
  );
};

export default AppHeader;
