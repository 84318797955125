const initialState = {
  title: '',
  collapsed: localStorage.getItem('collapsed') === 'true',
  categorySearchId: 0,
  profile: null,
  backdrop: false,
};

export default function app(state = initialState, { type, ...rest }) {
  switch (type) {
    case 'set':
      return { ...state, ...rest };
    case 'collapse':
      localStorage.setItem('collapsed', rest.collapsed);
      return { ...state, collapsed: rest.collapsed };

    default:
      return state;
  }
}
