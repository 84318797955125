const initialState = {
  schemaList: [],
  competences: [],
  pagination: {
    current: 1,
    total: 0,
    limit: 10,
  },
  params: {},
  loadingList: false,
  loadingSubmit: false,
};

export default function schema(state = initialState, action) {
  switch (action.type) {
    case 'setSchemaList':
      return {
        ...state,
        schemaList: action.payload?.items || [],
        pagination: {
          current: action.payload?.curPage || 1,
          total: action.payload?.itemsTotal || 0,
          limit: action.params?.limit || state.params?.limit || 10,
        },
        params: action.params,
      };
    case 'setLoadingList':
      return { ...state, loadingList: action.payload };
    case 'setLoadingSubmit':
      return { ...state, loadingSubmit: action.payload };
    case 'setCompetences':
      return { ...state, competences: action.payload || [] };

    default:
      return state;
  }
}
