import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import app from './app/reducer';
import user from './user/reducer';
import organization from '../pages/organization/services/reducer';
import document from '../pages/document/services/reducer';
import alumni from '../pages/alumni/services/reducer';
import schedule from '../pages/schedule/services/reducer';
import schema from '../pages/schema/services/reducer';
import notification from './notification/reducer';
import auditTrail from './audit-trail/reducer';

const reducer = combineReducers({
  app,
  user,
  organization,
  document,
  alumni,
  schedule,
  schema,
  notification,
  auditTrail,
});

const store = configureStore({
  reducer,
});

export default store;
