import React, { useEffect, useState } from 'react';
import { Tree, TreeNode } from 'react-organizational-chart';
import Title from 'antd/lib/typography/Title';
import TreeContent from './components/TreeContent';
import { Button, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import ModalCreate from './components/ModalCreate';
import { getDivisions, getStructures, getPositions } from './services/action';
import TreeLoading from './components/TreeLoading';
import { useDispatch, useSelector } from 'react-redux';
import ModalProfile from './components/ModalProfile';
import ModalDelete from './components/ModalDelete';
import ModalAssign from './components/ModalAssign';
import { getUsers } from '../../stores/user/action';
import { useSearchParams } from 'react-router-dom';

const Organization = props => {
  const [modalCreate, setModalCreate] = useState(false);
  const [modalProfile, setModalProfile] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [modalAssign, setModalAssign] = useState(false);
  const [dataSelected, setDataSelected] = useState(null);
  const structures = useSelector(state => state.organization.structures?.items || []);
  const loading = useSelector(state => state.organization.loadingStructures);
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    fetchStructures();
    dispatch(getPositions());
    dispatch(getDivisions());
    dispatch(getUsers());
  }, []);

  useEffect(() => {
    if (structures.length) {
      const selected = structures.find(item => item.id === parseInt(searchParams.get('view')));
      if (selected) {
        contentProps.onView(selected);
        searchParams.delete('view');
        setSearchParams(searchParams);
      }
    }
  }, [structures, searchParams]);

  const fetchStructures = () => {
    dispatch(getStructures());
  };

  const contentProps = {
    onCreate: data => {
      setDataSelected(data);
      setModalCreate(true);
    },
    onDelete: data => {
      setDataSelected(data);
      setModalDelete(true);
    },
    onView: data => {
      setDataSelected(data);
      setModalProfile(true);
    },
    onAssign: data => {
      setDataSelected(data);
      setModalAssign(true);
    },
  };

  const getTreeNode = parentId => {
    let childs = structures.filter(item => item.parent === parentId);
    childs.sort((a, b) => a.id - b.id);
    return childs.map((item, idx) => (
      <TreeNode key={idx} label={<TreeContent data={item} {...contentProps} />}>
        {getTreeNode(item.id)}
      </TreeNode>
    ));
  };

  const parent = structures.find(item => item.parent === 0);

  return (
    <div className="flex flex-col h-full p-5">
      <Title level={4}>Organization Management</Title>
      {loading && <TreeLoading />}
      {!loading && parent && (
        <Tree
          lineWidth={'2px'}
          lineHeight={'45px'}
          nodePadding={'1rem'}
          lineColor={'#5396C7'}
          lineBorderRadius={'10px'}
          label={<TreeContent data={parent} {...contentProps} />}
        >
          {getTreeNode(parent.id)}
        </Tree>
      )}
      {!loading && structures.length === 0 && (
        <div className="centered h-full">
          <div className="text-center border-2 border-gray-300 border-dashed p-5 rounded-xl">
            <Typography.Title level={5}>Belum ada Posisi dan Divisi yang bisa ditampilkan</Typography.Title>
            <Typography.Text>Silahkan buat posisi dan divisi berdasarkan struktur organisasi</Typography.Text>
            <div className="mt-5">
              <Button type="primary" icon={<PlusOutlined />} onClick={() => setModalCreate(true)}>
                Buat Posisi dan Divisi
              </Button>
            </div>
          </div>
        </div>
      )}
      <ModalCreate isOpen={modalCreate} onClose={() => setModalCreate(false)} data={dataSelected} />
      <ModalProfile isOpen={modalProfile} onClose={() => setModalProfile(false)} data={dataSelected} />
      <ModalDelete isOpen={modalDelete} onClose={() => setModalDelete(false)} data={dataSelected} />
      <ModalAssign isOpen={modalAssign} onClose={() => setModalAssign(false)} data={dataSelected} />
    </div>
  );
};

export default Organization;
