import moment from 'moment';
import DropdownAction from '../components/DropdownAction';
import { mapValue } from './converter';

const AlumniColumns = ({ selected = [], handleDelete, handleEdit, handlePreview }) => {
  return [
    {
      title: 'NIK',
      dataIndex: 'nik',
      key: 'nik',
      width: 170,
      className: 'im-pl-0',
      render: value => value || '-',
    },
    {
      title: 'Nama Asesi',
      dataIndex: 'full_name',
      key: 'full_name',
      width: 200,
      render: value => value || '-',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 200,
      render: value => value || '-',
    },
    {
      title: 'Skema Sertifikasi',
      dataIndex: 'schema_name',
      key: 'schema_name',
      width: 180,
      render: value => value || '-',
    },
    {
      title: 'Asesor Penguji',
      dataIndex: 'asesor_name',
      key: 'asesor_name',
      width: 180,
      render: value => value || '-',
    },
    {
      title: 'Status Keputusan',
      dataIndex: 'status_graduation',
      key: 'status_graduation',
      width: 150,
      render: value => mapValue(value),
    },
    {
      title: 'No Blanko',
      dataIndex: 'blanko_number',
      key: 'blanko_number',
      width: 130,
      render: value => value || '-',
    },
    {
      title: 'No Registrasi',
      dataIndex: 'registration_number',
      key: 'registration_number',
      width: 230,
      render: value => value || '-',
    },
    {
      title: 'No Sertifikat',
      dataIndex: 'certificate_number',
      key: 'certificate_number',
      width: 200,
      render: value => value || '-',
    },
    {
      title: 'Masa Berlaku',
      dataIndex: 'expired_date',
      key: 'expired_date',
      width: 130,
      render: value => moment(value).format('DD/MM/YYYY'),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: 100,
      align: 'center',
      render: (_, row) =>
        selected.length ? '' : <DropdownAction item={row} {...{ handleDelete, handleEdit, handlePreview }} />,
    },
  ];
};

export default AlumniColumns;
