import React, { useEffect, useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Image, Upload } from 'antd';
import uploadIcon from '../assets/icons/upload.svg';
import docIcon from '../assets/icons/doc.svg';
import { formatSize } from '../helpers/string';
import { BUCKET_URL } from '../configs/config';

const { Dragger } = Upload;

const UploadFile = ({
  disabled,
  onChange,
  onError,
  maxSize = 10,
  accept = '',
  acceptText = '',
  initValue,
  disableClose,
}) => {
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    if (initValue) {
      let size = getFileSize();
      setFileList([{ ...initValue, size }]);
    }
  }, []);

  const getFileSize = () => {
    const http = new XMLHttpRequest();
    try {
      http.open('HEAD', initValue?.file_url || BUCKET_URL + initValue?.file_path, false);
      http.send(null);
    } catch (error) {
      return 0;
    }
    if (http.status === 200) {
      return http.getResponseHeader('content-length');
    }
  };

  const props = {
    name: 'file',
    multiple: false,
    beforeUpload: file => {
      const accepts = accept?.split(',').map(item => item.trim());
      if (!accepts.includes(file?.type)) {
        onError('Mohon upload dengan tipe file yang sesuai!');
        return false;
      }
      const validSize = file.size / 1024 / 1024 < maxSize;
      if (!validSize) {
        onError('Ukuran file terlalu besar.');
        return false;
      }
      setFileList([...fileList, file]);
      onError('');
      onChange(file);
      return false;
    },
    showUploadList: false,
    disabled: disabled,
    fileList,
    accept,
  };

  const onRemove = () => {
    setFileList([]);
    onChange(null);
  };

  return (
    <div>
      {fileList.length > 0 ? (
        <div className="border rounded-lg p-3 flex">
          <div>
            <img src={docIcon} width={50} />
          </div>
          <div className="ml-3 w-full">
            <div className="flex justify-between">
              <div>
                <div>{fileList[0]?.name || '-'}</div>
                <div className="text-gray-400">{formatSize(fileList[0]?.size)}</div>
              </div>
              {!disableClose && <CloseOutlined style={{ fontSize: 20 }} onClick={onRemove} />}
            </div>
          </div>
        </div>
      ) : (
        <Dragger {...props}>
          <Image src={uploadIcon} preview={false} width={50} className="mb-2" />
          <p className="ant-upload-text" style={{ fontSize: 14 }}>
            Click to upload or drag & drop
          </p>
          <p className="ant-upload-hint" style={{ fontSize: 12 }}>
            {acceptText} (max. {maxSize} MB)
          </p>
        </Dragger>
      )}
    </div>
  );
};
export default UploadFile;
