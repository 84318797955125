import Schema from '../pages/schema/Schema';
import Document from '../pages/document/Document';
import Home from '../pages/home/Home';
import Organization from '../pages/organization/Organization';
import Schedule from '../pages/schedule/Schedule';
import Alumni from '../pages/alumni/Alumni';

const routes = [
  { path: '/', exact: true, name: 'Home', element: Home },
  { path: '/organization', exact: true, name: 'Organization Management', element: Organization },
  { path: '/document', exact: true, name: 'Document Management', element: Document },
  { path: '/schema', exact: true, name: 'Skema Sertifikasi', element: Schema },
  { path: '/schedule', exact: true, name: 'Jadwal Kegiatan', element: Schedule },
  { path: '/alumni', exact: true, name: 'Pemegang Sertifikat', element: Alumni },
];

export default routes;
