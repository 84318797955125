import moment from 'moment/moment';

export const formatSize = (size = 0) => {
  let result = 0;
  if (size > 1000000) {
    result = size / 1000000;
    return result.toFixed(2) + ' MB';
  } else if (size > 1000) {
    result = size / 1000;
    return result.toFixed(2) + ' KB';
  } else {
    return size + ' bytes';
  }
};

export const formatDate = (value, format = 'DD/MM/YYYY') => {
  if (!value) {
    return '-';
  }
  return moment(value).format(format);
};

export const formatDateTime = (value, format = 'DD/MM/YYYY HH:mm') => {
  if (!value) {
    return '-';
  }
  return moment(value).format(format);
};

export const getFirstChar = value => {
  if (!value) {
    return '';
  }
  return Array.from(value)[0];
};

export const getInitialName = (firstName = '', last_name = '') => {
  if (!firstName) {
    return '';
  }
  return firstName[0] + (last_name[0] || '');
};

export const randomColor = () => {
  let color = '#';
  let hexaDecimal = '0123456789ABCDEF';

  for (let i = 0; i < 6; i++) {
    color = color + hexaDecimal[Math.floor(Math.random() * 12)];
  }
  return color;
};

export const formatRelativeTime = date => {
  let now = moment();
  let last = moment(date);
  let isSameDay = now.format('YYYY-MM-DD') === last.format('YYYY-MM-DD');
  if (now.diff(last) / 60000 < 60) {
    return moment(date).locale('id').startOf('minute').fromNow();
  } else if (now.diff(last) / 60000 < 60 * 24 && isSameDay) {
    return moment(date).locale('id').format('HH:mm');
  }
  return moment(date).locale('id').format('dddd, DD MMMM YYYY HH:mm');
};
