export const mapLogContent = data => {
  const apiGroup = {
    organization_structures: {
      POST: 'menambahkan struktur organisasi baru',
      PUT: (
        <>
          mengubah data struktur organisasi{' '}
          <b>
            {data?.new_data?.position_name} {data?.new_data?.division_name}
          </b>
        </>
      ),
      DELETE: (
        <>
          menghapus data struktur organisasi{' '}
          <b>
            {data?.old_data?.position_name} {data?.old_data?.division_name}
          </b>
        </>
      ),
    },
    'organization_structures/assign': {
      PUT: (
        <>
          menugaskan{' '}
          <b>
            {data?.new_data?.first_name} {data?.new_data?.last_name}
          </b>{' '}
          sebagai{' '}
          <b>
            {data?.new_data?.position_name} {data?.new_data?.division_name}
          </b>
        </>
      ),
    },
    documents: {
      POST: <>menambahkan dokumen {data?.new_data?.document_name ? <b>{data?.new_data?.document_name}</b> : 'baru'}</>,
      PUT: (
        <>
          mengubah data dokumen <b>{data?.new_data?.document_name}</b>
        </>
      ),
      DELETE: (
        <>
          menghapus dokumen <b>{data?.old_data?.document_name}</b>
        </>
      ),
    },
    schedules: {
      POST: <>membuat jadwal kegiatan {data?.new_data?.title ? <b>{data?.new_data?.title}</b> : 'baru'}</>,
      PUT: (
        <>
          mengubah jadwal kegiatan <b>{data?.new_data?.title}</b>
        </>
      ),
      DELETE: (
        <>
          membatalkan jadwal kegiatan <b>{data?.old_data?.title}</b>
        </>
      ),
    },
    schedules_participants: {
      POST: (
        <>
          menambahkan partisipan di kegiatan <b>{data?.new_data?.title}</b>
        </>
      ),
      DELETE: (
        <>
          menghapus{' '}
          <b>
            {data?.old_data?.first_name} {data?.old_data?.last_name}
          </b>{' '}
          dari partisipan kegiatan <b>{data?.old_data?.title}</b>
        </>
      ),
    },
    alumnis: {
      POST: 'menambahkan data pemegang sertifikat baru',
      PUT: (
        <>
          mengubah data pemegang sertifikat <b>{data?.new_data?.title}</b>
        </>
      ),
      DELETE: (
        <>
          menghapus data pemegang sertifikat <b>{data?.old_data?.title}</b>
        </>
      ),
    },
    'alumnis/bulk': {
      DELETE: 'menghapus beberapa data pemegang sertifikat',
    },
    'alumnis/import': {
      POST: 'mengimport data pemegang sertifikat baru',
    },
    certification_schemes: {
      POST: 'menambahkan data skema sertifikasi baru',
      PUT: 'mengubah data skema sertifikasi',
      DELETE: 'menghapus data skema sertifikasi',
    },
    lsp: {
      POST: 'menambahkan data LSP baru',
      PUT: 'mengubah data LSP',
      DELETE: 'menghapus data LSP',
    },
  };
  return (
    <div>
      <b>{`${data?.first_name} ${data?.last_name}`}</b>{' '}
      {apiGroup?.[data?.api_groups]?.[data?.action] || 'melakukan sesuatu'}
    </div>
  );
};

export const mapLogRedirect = (data, navigate, dispatch) => {
  const apiGroup = {
    organization_structures: '/organization',
    'organization_structures/assign': `/organization?view=${data?.new_data?.id}`,
    documents: '/document',
    certification_schemes: '/schema',
    schedules: '/schedule',
    schedules_participants: '/schedule',
    alumnis: '/alumni',
    'alumnis/bulk': '/alumni',
    'alumnis/import': '/alumni',
  };

  if (navigate && data.action !== 'DELETE') {
    if (data.api_groups === 'documents') {
      dispatch({ type: 'set', categorySearchId: data?.new_data?.doc_categories_id });
    }
    navigate(apiGroup?.[data?.api_groups]);
  }
};
