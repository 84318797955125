import { randomColor } from '../../../helpers/string';

const initialState = {
  schedules: [],
  tukList: [],
  pagination: {
    current: 1,
    total: 0,
    limit: 10,
  },
  params: {},
  loadingList: false,
  loadingSubmit: false,
};

export default function schedule(state = initialState, action) {
  switch (action.type) {
    case 'setSchedules':
      const data = action.payload?.items?.map(item => {
        item.participants = item.participants?.map(part => ({ ...part, bg_color: randomColor() }));
        return item;
      });
      return {
        ...state,
        schedules: data,
        pagination: {
          current: action.payload?.curPage || 1,
          total: action.payload?.itemsTotal || 0,
          limit: action.params?.limit || state.params?.limit || 10,
        },
        params: action.params,
      };
    case 'setLoadingListSchedule':
      return { ...state, loadingList: action.payload };
    case 'setLoadingSubmitSchedule':
      return { ...state, loadingSubmit: action.payload };
    case 'setTuk':
      return { ...state, tukList: action.payload?.items || [] };

    default:
      return state;
  }
}
