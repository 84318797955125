import React, { useEffect, useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Image, Progress, Upload } from 'antd';
import uploadIcon from '../assets/icons/upload.svg';
import docIcon from '../assets/icons/doc.svg';
import { formatSize } from '../helpers/string';
import { uploadToS3 } from '../helpers/aws-s3';
import { BUCKET_URL } from '../configs/config';

const { Dragger } = Upload;

let interval;

const UploadS3 = ({
  disabled,
  dirName,
  fileName,
  onChange,
  onPreview,
  onError,
  maxSize = 10,
  accept = '',
  acceptText = '',
  initValue,
  disableClose,
}) => {
  const [progress, setProgress] = useState(0);
  const [fileList, setFileList] = useState([]);
  const [errorUpload, setErrorUpload] = useState(false);

  useEffect(() => {
    if (initValue) {
      let size = getFileSize();
      setFileList([{ ...initValue, size }]);
      setProgress(100);
      onChange({ location: initValue?.file_url, file_path: initValue?.file_path });
    }
  }, []);

  const getFileSize = () => {
    const http = new XMLHttpRequest();
    try {
      http.open('HEAD', initValue?.file_url || BUCKET_URL + initValue?.file_path, false);
      http.send(null);
    } catch (error) {
      return 0;
    }
    if (http.status === 200) {
      return http.getResponseHeader('content-length');
    }
  };

  const props = {
    name: 'file',
    multiple: false,
    beforeUpload: file => {
      const accepts = accept?.split(',').map(item => item.trim());
      if (!accepts.includes(file?.type)) {
        onError('Mohon upload dengan tipe file yang sesuai!');
        return false;
      }
      const validSize = file.size / 1024 / 1024 < maxSize;
      if (!validSize) {
        onError('Ukuran file terlalu besar.');
        return false;
      }
      onError('');
      setFileList([...fileList, file]);
      setErrorUpload(false);
      handleUpload(file);
      interval = setInterval(() => {
        setProgress(pre => {
          if (pre >= 80) {
            clearInterval(interval);
          }
          return pre + 1;
        });
      }, 30);
      return false;
    },
    showUploadList: false,
    disabled: disabled,
    fileList,
    accept,
  };

  const onRemove = () => {
    setProgress(0);
    setFileList([]);
    onChange(null);
  };

  const handleUpload = file => {
    uploadToS3(file, dirName, fileName)
      .then(data => {
        setProgress(100);
        onChange(data);
      })
      .catch(err => {
        clearInterval(interval);
        onError('File gagal diupload');
        setErrorUpload(true);
      });
  };

  return (
    <div>
      {fileList.length > 0 ? (
        <div className="border rounded-lg p-3 flex">
          <div>
            <img src={docIcon} width={50} />
          </div>
          <div className="ml-3 w-full">
            <div className="flex justify-between">
              <div>
                <div className={onPreview ? 'cursor-pointer hover:text-primary' : ''} onClick={onPreview}>
                  {fileList[0]?.name || '-'}
                </div>
                <div className="text-gray-400">{formatSize(fileList[0]?.size)}</div>
              </div>
              {!disableClose && <CloseOutlined style={{ fontSize: 20 }} onClick={onRemove} />}
            </div>
            {progress < 100 && (
              <Progress
                percent={progress}
                strokeColor={!errorUpload && '#5396C7'}
                status={errorUpload && 'exception'}
              />
            )}
          </div>
        </div>
      ) : (
        <Dragger {...props}>
          <Image src={uploadIcon} preview={false} width={50} className="mb-2" />
          <p className="ant-upload-text" style={{ fontSize: 14 }}>
            Click to upload or drag & drop
          </p>
          <p className="ant-upload-hint" style={{ fontSize: 12 }}>
            {acceptText} (max. {maxSize} MB)
          </p>
        </Dragger>
      )}
    </div>
  );
};
export default UploadS3;
