import { Badge, Popover, Tabs } from 'antd';
import { BellFilled, LoadingOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DotIcon } from '../../components/Icons';
import { getTokenData } from '../../helpers/token';
import LogChildren from './LogChildren';
import NotifChildren from './NotifChildren';

const Notification = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const notifications = useSelector(state => state.notification.list);
  const loading = useSelector(state => state.notification.loading);
  const loadingRead = useSelector(state => state.notification.loadingRead);
  const loadingAuditTrail = useSelector(state => state.auditTrail.loading);
  const user = getTokenData();

  const isContainUnread = notifications.some(item => !item.is_read);

  const content = () => {
    return (
      <div className="tab-notif">
        <Tabs
          type="card"
          items={[
            {
              label: (
                <div className="flex items-center">
                  {!loading && !loadingRead && isContainUnread && (
                    <DotIcon style={{ fontSize: 10, color: '#D42929', marginRight: 8 }} />
                  )}
                  {(loading || loadingRead) && <LoadingOutlined />}
                  Notifikasi
                </div>
              ),
              key: 1,
              children: <NotifChildren isContainUnread={isContainUnread} />,
            },
            user?.role_code === 'SUP' && {
              label: (
                <div className="flex items-center">
                  {loadingAuditTrail && <LoadingOutlined />}
                  Log Sistem
                </div>
              ),
              key: 2,
              children: <LogChildren setOpen={setOpen} />,
            },
          ]}
          tabBarGutter={0}
          tabBarStyle={{ margin: 0 }}
        />
      </div>
    );
  };

  const handleOpen = value => {
    setOpen(value);
    dispatch({ type: 'set', backdrop: value });
  };

  return (
    <Popover
      placement="bottomRight"
      overlayClassName="popover-notif"
      content={content}
      trigger="click"
      open={open}
      onOpenChange={handleOpen}
      overlayInnerStyle={{ overflow: 'hidden' }}
    >
      <Badge dot={isContainUnread} offset={[-3, 3]}>
        <BellFilled style={{ fontSize: 18, color: '#5396C7', cursor: 'pointer' }} />
      </Badge>
    </Popover>
  );
};

export default Notification;
