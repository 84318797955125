import { Form, Input, Space, Typography } from 'antd';
import { CloseOutlined, EditOutlined, EyeFilled, LoadingOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { deleteSchemaUnit, getSchemaList } from '../services/action';
import { useDispatch } from 'react-redux';

const CompetenceUnitItem = ({ field, remove, handleEditUnit, formType, data }) => {
  const dispatch = useDispatch();
  const form = Form.useFormInstance();
  const { key, name, ...restField } = field;
  const [loadingDelete, setLoadingDelete] = useState(false);

  const handleRemove = () => {
    if (data) {
      const unit = form.getFieldValue('unit_competences')[key];
      setLoadingDelete(true);
      deleteSchemaUnit(data?.id, unit?.unit_competences_id)
        .then(() => {
          dispatch(getSchemaList());
          remove(name);
        })
        .finally(() => setLoadingDelete(false));
    } else {
      remove(name);
    }
  };

  return (
    <Space key={key} style={{ display: 'flex', justifyContent: 'space-between' }} align="baseline">
      <Form.Item {...restField} name={[name, 'unit_name']}>
        <Typography.Text>{form.getFieldValue('unit_competences')[name].unit_name}</Typography.Text>
      </Form.Item>
      <Form.Item {...restField} name={[name, 'unit_code']} hidden>
        <Input />
      </Form.Item>
      <Form.Item {...restField} name={[name, 'unit_code']} hidden>
        <Input />
      </Form.Item>
      <Form.Item {...restField} name={[name, 'unit_competences_id']} hidden>
        <Input />
      </Form.Item>
      <Space>
        {formType !== 'Detail' ? (
          <>
            <EditOutlined onClick={() => handleEditUnit(name)} style={{ color: '#5396C7' }} />
            {loadingDelete ? (
              <LoadingOutlined />
            ) : (
              <CloseOutlined onClick={() => handleRemove(name)} style={{ color: '#FF3131' }} />
            )}
          </>
        ) : (
          <EyeFilled onClick={() => handleEditUnit(name)} style={{ color: '#5396C7' }} />
        )}
      </Space>
    </Space>
  );
};

export default CompetenceUnitItem;
