import { Avatar, Empty, Spin } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { getAuditTrails } from '../../stores/audit-trail/action';
import { formatRelativeTime, getInitialName, randomColor } from '../../helpers/string';
import { mapLogContent, mapLogRedirect } from '../../helpers/map';
import { useNavigate } from 'react-router-dom';

const LogChildren = ({ setOpen }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const logs = useSelector(state => state.auditTrail.list || []);
  const logParam = useSelector(state => state.auditTrail.params);
  const logCount = useSelector(state => state.auditTrail.count);

  const handleNextLog = () => {
    dispatch(getAuditTrails({ ...logParam, page: logParam?.page + 1 }, true));
  };

  const handleClickLog = data => {
    setOpen(false);
    dispatch({ type: 'set', backdrop: false });
    mapLogRedirect(data, navigate, dispatch);
  };

  return (
    <div className="overflow-auto max-h-96" id="logScrollabeDiv">
      <InfiniteScroll
        dataLength={logs.length}
        hasMore={logs.length < logCount}
        next={handleNextLog}
        loader={
          <div className="text-center p-5">
            <Spin tip="Memuat..." />
          </div>
        }
        scrollableTarget="logScrollabeDiv"
      >
        {logs.map((item, idx) => (
          <div
            key={idx}
            className="p-5 border-b-2 flex items-center cursor-pointer hover:bg-gray-50"
            onClick={() => handleClickLog(item)}
          >
            <div className="mr-4">
              <Avatar
                size="large"
                style={{ backgroundColor: randomColor(), cursor: 'default' }}
                className="font-semibold"
              >
                {getInitialName(item.first_name, item.last_name)}
              </Avatar>
            </div>
            <div className="w-full">
              <div className="text-right text-gray-400">{formatRelativeTime(item.created_at)}</div>
              {mapLogContent(item)}
            </div>
          </div>
        ))}
      </InfiniteScroll>
      {logs.length === 0 && (
        <div className="p-5">
          <Empty description="Tidak ada log sistem" />
        </div>
      )}
    </div>
  );
};

export default LogChildren;
