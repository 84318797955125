import { notification } from 'antd';
import { ASSESSMENT_URL } from '../../../configs/config';
import { httpAuth } from '../../../helpers/http';

export const getAlumniList = params => async dispatch => {
  try {
    dispatch({ type: 'setLoadingList', payload: true });
    const res = await httpAuth().get(`${ASSESSMENT_URL}/alumnis`, { params });
    dispatch({ type: 'setAlumniList', payload: res.data, params });
    dispatch({ type: 'setLoadingList', payload: false });
    return res.data;
  } catch (err) {
    dispatch({ type: 'setLoadingList', payload: false });
    throw err?.response;
  }
};

export const addAlumni = data => async dispatch => {
  try {
    dispatch({ type: 'setLoadingSubmit', payload: true });
    const res = await httpAuth().post(`${ASSESSMENT_URL}/alumnis`, data);
    dispatch({ type: 'setLoadingSubmit', payload: false });
    return res.data;
  } catch (err) {
    dispatch({ type: 'setLoadingSubmit', payload: false });
    notification.error({
      message: 'Terjadi kesalahan',
      description: err?.response?.data?.message || 'Mohon coba beberapa saat lagi',
    });
    throw err?.response;
  }
};

export const importAlumni = data => async dispatch => {
  try {
    dispatch({ type: 'setLoadingSubmit', payload: true });
    const res = await httpAuth().post(`${ASSESSMENT_URL}/alumnis/import/asesi`, data);
    dispatch({ type: 'setLoadingSubmit', payload: false });
    return res.data;
  } catch (err) {
    dispatch({ type: 'setLoadingSubmit', payload: false });
    notification.error({
      message: 'Terjadi kesalahan',
      description: err?.response?.data?.message || 'Mohon coba beberapa saat lagi',
    });
    throw err?.response;
  }
};

export const updateAlumni = (id, data) => async dispatch => {
  try {
    dispatch({ type: 'setLoadingSubmit', payload: true });
    const res = await httpAuth().put(`${ASSESSMENT_URL}/alumnis/${id}`, data);
    dispatch({ type: 'setLoadingSubmit', payload: false });
    return res.data;
  } catch (err) {
    dispatch({ type: 'setLoadingSubmit', payload: false });
    notification.error({
      message: 'Terjadi kesalahan',
      description: err?.response?.data?.message || 'Mohon coba beberapa saat lagi',
    });
    throw err?.response;
  }
};

export const deleteAlumni = async id => {
  try {
    const res = await httpAuth().delete(`${ASSESSMENT_URL}/alumnis/${id}`);
    return res.data;
  } catch (err) {
    notification.error({
      message: 'Terjadi kesalahan',
      description: err?.response?.data?.message || 'Mohon coba beberapa saat lagi',
    });
    throw err?.response;
  }
};

export const deleteBulkAlumni = async data => {
  try {
    const res = await httpAuth().delete(`${ASSESSMENT_URL}/alumnis`, { data });
    return res.data;
  } catch (err) {
    notification.error({
      message: 'Terjadi kesalahan',
      description: err?.response?.data?.message || 'Mohon coba beberapa saat lagi',
    });
    throw err?.response;
  }
};
